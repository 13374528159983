import { useEffect, useState } from 'react'
import './App.scss';
import './Icons.js';
import { NavLink, Outlet } from 'react-router';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { Notifications } from './modules/Notification';
import { usePatchUserMutation } from './redux/reducers/api';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { userSynced } from './redux/reducers/auth';
import { AuthProvider } from './auth/AuthProvider';
import { useDispatch } from 'react-redux';

export const DashboardLayout = () => {
  return <AuthProvider>
    <InnerDashboardLayout />
  </AuthProvider>
}

const renderAdminMenu = () => {
  return <>
      <p className="menu-label">
        Admin
      </p>
      <ul className="menu-list">
        <li><NavLink to={`/dashboard/admin/webhooks`} className="is-dark">Webhooks</NavLink></li>
        <li><NavLink to={`/dashboard/admin/usernames`} className="is-dark">Usernames</NavLink></li>
      </ul>
    </>
}

const ApiUnavailable = () => {
  return <>
    <h1 className="title">API is unavailable</h1>
    <h2 className="subtitle">Try again later</h2>
  </>
}

export const InnerDashboardLayout = withAuthenticationRequired(() => {
  const [isAdmin, setIsAdmin] = useState(false)
  const { buildLogoutUrl, getAccessTokenSilently, user } = useAuth0();
  const [patchUser, patchUserResult] = usePatchUserMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    getAccessTokenSilently().then((token: string) => {
      localStorage.setItem('auth_token', token)
      patchUser(user)
    })
  }, [getAccessTokenSilently, user, patchUser])
  useEffect(() => {
      if (patchUserResult.status === QueryStatus.fulfilled) {
        dispatch(userSynced(patchUserResult.data))
        if (patchUserResult.data.roles.indexOf('admin') > -1) {
          setIsAdmin(true)
        }
      }
  }, [dispatch, patchUserResult, setIsAdmin])

  if (patchUserResult.status === QueryStatus.rejected) {
    return <ApiUnavailable />
  }

  return <div id="main" className="columns">
        <article className="column p-5">
          <div className="block">
            <Notifications />
            <Outlet />
          </div>
        </article>
        <aside className="menu column is-2 p-5">
          <header className="navbar">
            <div className="navbar-brand is-align-items-baseline">
              <p className="title is-size-1">Veld</p>
              <p className="title is-size-5">services</p>
            </div>
          </header>
          <nav className="">
            <p className="menu-label">Modules</p>
            <ul className="menu-list">
              <li><NavLink to="/dashboard" className="is-dark">Dashboard</NavLink></li>
              <li><NavLink to="/dashboard/songlist" className="is-dark">Songlist</NavLink></li>
              <li><NavLink to="/dashboard/connections" className="is-dark">Connections</NavLink></li>
              <li><a href={ buildLogoutUrl() } className="is-dark">Logout</a></li>
            </ul>
            <p className="menu-label">User ({user?.name})</p>
            <ul className="menu-list">
              <li><NavLink to="/dashboard/user/settings" className="is-dark">Settings</NavLink></li>
            </ul>
            { isAdmin ? renderAdminMenu() : '' }
          </nav>
        </aside>
      </div>
}, {
  onRedirecting: () => (<div className="is-size-3 p-5">Logging in...</div>)
});

// const App = () => {
//   const [hasToken, setHasToken] = useState(false)
//   const dispatch = useAppDispatch()
//   const { buildLogoutUrl, getAccessTokenSilently, user } = useAuth0();
// 
//   const matcher = useMatch('*');
//   const path = matcher?.pathname || '';
//   const [patchUser, patchUserResult] = usePatchUserMutation()
// 
//   useEffect(() => {
//     getAccessTokenSilently().then((token: string) => {
//       localStorage.setItem('auth_token', token)
//       patchUser(user)
//       setHasToken(true)
//     })
//   }, [getAccessTokenSilently, user, patchUser])
//   useEffect(() => {
//       if (patchUserResult.status === QueryStatus.fulfilled) {
//         dispatch(userSynced(patchUserResult.data))
//       }
//   }, [dispatch, patchUserResult])
// 
//   const renderMainContent = () => {
//     
//     if (!hasToken) {
//       return <p>Verifying token</p>
//     }
// 
//     console.log(path)
// 
//     switch (patchUserResult.status) {
//     case QueryStatus.rejected:
//       return <><h1>Error</h1><p>API is unavailable 😥</p></>
//     case QueryStatus.pending:
//     case QueryStatus.uninitialized:
//       return <Loading />
//     case QueryStatus.fulfilled:
//               return <Routes>
//                 <Route path="/" Component={Dashboard} />
//                 <Route path={`/songlist`} Component={Songlist} />
//                 <Route path={`/connections`} Component={Connections} />
//                 <Route path={`/user/settings`} Component={UserSettings} />
//                 <Route path={`/user`} Component={User} />
// 
//                 {/* ADMIN PAGES */}
//                 <Route path={`${path}/webhooks`} Component={Webhooks} />
//                 <Route path={`${path}/usernames`} Component={Usernames} />
//               </Routes>
//     }
//   }
// 
//   const renderAdminMenu = () => {
//     return <>
//         <p className="menu-label">
//           Admin
//         </p>
//         <ul className="menu-list">
//           <li><NavLink to={`/dashboard/webhooks`} className="is-dark">Webhooks</NavLink></li>
//           <li><NavLink to={`/dashboard/usernames`} className="is-dark">Usernames</NavLink></li>
//         </ul>
//       </>
//   }
// 
//   const roles = patchUserResult.data?.roles || [];
// 
//   return <div id="main" className="columns">
//         <article className="column">
//           <div className="block">
//             <Notifications />
//             <Outlet />
//             {renderMainContent()}
//           </div>
//         </article>
//         <aside className="menu column is-2 p-5">
//           <header className="navbar">
//             <div className="navbar-brand is-align-items-baseline">
//               <p className="title is-size-1">Veld</p>
//               <p className="title is-size-5">services</p>
//             </div>
//           </header>
//           <nav className="">
//             <p className="menu-label">Modules</p>
//             <ul className="menu-list">
//               <li><NavLink to="/" className="is-dark">Dashboard</NavLink></li>
//               <li><NavLink to={`/songlist`} className="is-dark">Songlist</NavLink></li>
//               <li><NavLink to={`/connections`} className="is-dark">Connections</NavLink></li>
//               <li><a href={ buildLogoutUrl() } className="is-dark">Logout</a></li>
//             </ul>
//             <p className="menu-label">User ({user?.name})</p>
//             <ul className="menu-list">
//               <li><NavLink to={`/user/settings`} className="is-dark">Settings</NavLink></li>
//             </ul>
//             { roles.indexOf("admin") >= 0 ? renderAdminMenu() : '' }
//           </nav>
//         </aside>
//       </div>
// }
// 
// export const AppLayout = withAuthenticationRequired(App, {
//   onRedirecting: () => (<div>Logging in...</div>)
// });
