import { DateTime } from "luxon";

export type UsernameChanges = {
  [username: string]: UsernameState
}

export type UsernameState = {
  user_id: string;
  current_login: string;
  current_displayname: string;
  first_seen_at: luxon.DateTime;
  last_change: luxon.DateTime;
  changes: UsernameChange[];
}

export type ChangeType = "username" | "display_name";

export type UsernameChange = {
  type: ChangeType;
  old: string;
  new: string;
  changed_on: luxon.DateTime;
}

export type JsonUsernameChanges = {
  [username: string]: {
    user_id: string;
    current_login: string;
    current_displayname: string;
    first_seen_at: string;
    last_change: string;
    changes: {
      type: string;
      old: string;
      new: string;
      changed_on: string;
    }[];
  }
}

export const FromJson = (json: JsonUsernameChanges): UsernameChanges => {
  const entries = Object.entries(json)
  return Object.fromEntries(entries.map(([userId, raw]): [string, UsernameState] => {
    return [userId, {
      user_id: raw.user_id,
      current_login: raw.current_login,
      current_displayname: raw.current_displayname,
      first_seen_at: DateTime.fromISO(raw.first_seen_at),
      last_change: DateTime.fromISO(raw.last_change),
      changes: raw.changes.map((change): UsernameChange => {
        const changed_on = DateTime.fromISO(change.changed_on)
        if (!changed_on.isValid) {
          throw Error("invalid date in API response")
        }
        let type: ChangeType = "username"
        if (change.type === "display_name") {
          type = "display_name"
        }

        return {
          type: type,
          old: change.old,
          new: change.new,
          changed_on: changed_on,
        }
      })
    }]
  }))
}

export const SortByLastChange = (changes: UsernameChanges): UsernameState[] => {
  return Object.values(changes).toSorted((a: UsernameState, b: UsernameState) => a.last_change > b.last_change ? -1 : 1)
}
