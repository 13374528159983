import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import { rtk } from './reducers/api';

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(rtk.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// temporary until everything is TSX
export default store
