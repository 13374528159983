import { createSlice } from '@reduxjs/toolkit';

interface BadgeVersion {
  id: string,
  title: string,
  image_url_1x: string,
  image_url_2x: string,
  image_url_4x: string,
}

interface BadgeSet {
  set_id: string,
  versions: { [version: string]: BadgeVersion }
}

export type BadgeSets = {
  [set_id: string]: BadgeSet,
}

export type Video = {
  streamer: string;
  youtubeId: string;
  streamStartedAt: string,
  badgeSets: BadgeSets,
  chat: JsonChatMsg[],
  bttv: Bttv,
  loaded: boolean,
  error?: string,
  channelBadges: rawChannelBadge[]
};

type Bttv = {
  channelEmotes: BttvEmote[],
  sharedEmotes: BttvEmote[],
};

export type BttvEmote = {
  id: string,
  code: string,
};

const initialState: Video = {
    streamer: '',
    youtubeId: '',
    streamStartedAt: '',
    badgeSets: {},
    chat: [],
    bttv: {channelEmotes: [], sharedEmotes: []},
    channelBadges: [], // raw response
    loaded: false,
    error: undefined
}

type rawChannelBadge = {
  set_id: string,
  versions: BadgeVersion[]
}

export const mapBadgesById = (channelBadges: rawChannelBadge[]): BadgeSets => {
  let badges: BadgeSets = {}

  channelBadges.forEach((set: rawChannelBadge) => {
    if (badges.hasOwnProperty(set.set_id)) { return; } // skip existing sets
    let versions: {[set_id: string]: BadgeVersion} = {}
    set.versions.forEach((version: BadgeVersion) => {
      versions[version.id] = version
    })
    badges['' + set.set_id] = {
      set_id: set.set_id,
      versions: versions,
    }
  })

  return badges
}

const videoSlice = createSlice({
  name: 'video',
  initialState: initialState,
  reducers: {
    VideoLoading() { },
    VideoLoaded(state, response) {
      state.loaded = true;
      state.streamer = response.payload.data.streamer;
      state.youtubeId = response.payload.data.youtubeId;
      state.streamStartedAt = response.payload.data.streamStartedAt;
      state.bttv.channelEmotes = response.payload.data.bttv.channelEmotes;
      state.bttv.sharedEmotes = response.payload.data.bttv.sharedEmotes;

      if (response.payload.data.channelBadges !== null) {
        response.payload.data.channelBadges.forEach((set: any) => {
          if (state.badgeSets.hasOwnProperty(set.set_id)) {
            // don't allow global sets to override channel sets
            return;
          }
          state.badgeSets['' + set.set_id] = {
            set_id: set.set_id,
            versions: {}
          }
          set.versions.forEach((version: BadgeVersion) => {
            state.badgeSets['' + set.set_id].versions[version.id] = version;
          });
        })
      }
    },
    VideoError(state, error) {
      state.error = error.payload.message;
    },
    ChatLoaded(state, response) {
      const messages = response.payload.data // .map((msg: JsonChatMsg) => ChatMsg.fromJson(msg))
      state.chat = messages;
    }
  },
});

export const { VideoLoading, VideoLoaded, VideoError, ChatLoaded } = videoSlice.actions;

export default videoSlice.reducer;

export interface JsonChatMsg {
  type: string;
  datetime: string;
  username: string;
  display_name: string;
  message: string;
  color: string;
  emotes: string;
  badges: string;
  system_message: string|undefined;
}
