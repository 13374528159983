import { PropsWithChildren } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

const isOauthReturn = () => {
  // some of the oauth redirects here would trigger auth0s redirect callback, so we just skip all of them for now
  // this will probably break linking to this page, so in the future we want to identify the specific redirects that should be ignored
  return window.location.pathname.startsWith('/connections');
}

interface AuthProps {
}

const AuthProvider = (props: PropsWithChildren<AuthProps>) => {
  const navigate = useNavigate()
  return <Auth0Provider
      domain={process.env.REACT_APP_AUTHZERO_DOMAIN||''}
      audience={process.env.REACT_APP_AUTHZERO_AUDIENCE||''}
      clientId={process.env.REACT_APP_AUTHZERO_CLIENT_ID||''}
      redirectUri={window.location.origin + "/dashboard"}
      skipRedirectCallback={isOauthReturn()}
      onRedirectCallback={state => {
        if (state && state.returnTo) {
          navigate(state.returnTo)
          // document.location.href  = state.returnTo
        }
      }}
    >
      {props.children}
    </Auth0Provider>
}

export { AuthProvider }
