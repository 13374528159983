import { createSlice } from '@reduxjs/toolkit';

export type UserModule = {
  ModuleId: string 
  Enabled: boolean
}

export type Module = {
  id: string
  name: string
  description: string
  settings: ModuleSetting[]
}

export enum ModuleSettingType {
  TemplatedString = "templated_string"
}

export type ModuleSetting = {
  name: string,
  label: string,
  default: string,
  required: boolean,
  nullable: boolean,
  type: ModuleSettingType,
}

type ModuleSlice = {
  modules: Module[]
  userModules: UserModule[],
  status: string
  error?: string
}

const initialState: ModuleSlice = {
    modules: [],
    userModules: [],
    status: 'idle',
    error: undefined
  }

const modulesSlice = createSlice({
  name: 'modules',
  initialState: initialState,
  reducers: {
    modulesLoading(state) {
      state.status = 'loading';
    },
    moduleEnabled(state, action) {
      const enabledModule = action.payload.data
      let moduleFound = false
      state.userModules = state.userModules.map((um: UserModule) => {
        if (um.ModuleId === enabledModule.ModuleId) {
          um.Enabled = enabledModule.Enabled
          moduleFound = true
        }
        return um
      })
      if (false === moduleFound) {
        state.userModules.push(enabledModule)
      }
      state.status = 'idle';
    },
    modulesLoaded(state, action) {
      state.modules = action.payload.data;
      state.status = 'idle';
    },
    userModulesLoaded(state, action) {
      state.userModules = action.payload.data
      state.status = 'idle';
    },
    modulesError(state, error) {
      state.status = 'errored';
      state.error = error.payload.message;
    }
  }
});

export const { modulesLoading, moduleEnabled, userModulesLoaded, modulesLoaded, modulesError } = modulesSlice.actions;

export default modulesSlice.reducer;

