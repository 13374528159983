import { withAuth0, WithAuth0Props } from "@auth0/auth0-react"
import { useGetUsernameChangesQuery } from "../../redux/reducers/api"
import Loading from "../../Loading"
import { FromJson, SortByLastChange, UsernameChange, UsernameState } from "../../redux/reducers/username-changes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

type Props = {} & WithAuth0Props

const Usernames = (props: Props) => {
  const { data, isLoading, isError } = useGetUsernameChangesQuery()

  if (isLoading) {
    return <Loading />
  }

  if (isError || data === undefined) {
    return <p>Oops</p>
  }

  const usernames = SortByLastChange(FromJson(data))
  return <div>
    <h1 className="is-size-1">Usernames</h1>
    <table className="table is-striped">
      <thead>
        <tr>
          <th className="has-text-grey">User ID</th>
          <th>Current Displayname </th>
          <th className="fix-grid has-3-cols"><div className="grid"><div className="cell has-text-right">Changes</div><div className="cell is-col-span-2" /></div></th>
        </tr>
        { usernames.map((user: UsernameState) => <Username key={user.user_id} id={user.user_id} username={user} />) }
      </thead>
    </table>
  </div>
}

const Username = (props: {id: string, username: UsernameState}) => {
  return <tr>
    <td className="has-text-grey">{ props.id }</td>
    <td>{ props.username.current_displayname }</td> 
    <td className="fixed-grid has-3-cols"><div className="grid">{ props.username.changes.map((change, index) => <Change key={"change-" + index} change={change} state={props.username} />) }</div></td>
  </tr>
}

const Change = ({ change, state }: {change: UsernameChange, state: UsernameState}) => {
  return <>
    <div className="cell is-size-7 has-text-grey has-text-right">{change.type}</div>
    <div className="cell">{ change.old } <FontAwesomeIcon icon={faArrowRight} className="has-text-success" /> { change.new }</div>
    <div className="cell is-size-7 has-text-grey">{ change.changed_on.toFormat("dd MMM") } '{ change.changed_on.toFormat('yy HH:ii') }</div>
  </>
}

export default withAuth0(Usernames)
