import {useState} from 'react';
import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AddSongForm = props => {
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [isSubmitting, setSubmitting] = useState(false);
    const [origin, setOrigin] = useState('');
    const [title, setTitle] = useState('');
    const dispatch = useDispatch();

    const submit = e =>  {
        e.preventDefault();
        setSubmitting(true);
        getAccessTokenSilently().then(async token => {
            //await dispatch(addSong(token, 'default', {
            //    origin: origin,
            //    title: title,
            //}));
            setOrigin('');
            setTitle('');
            setSubmitting(false);
            props.close();
        });
    };

    if (!isAuthenticated) {
        return '401';
    }

    return <form className="has-text-light">
        <h3 className={"is-size-3"}>Add song</h3>
        <div className="field is-horizontal">
            <div className="field-label is-normal">
                <label className="label">Artist</label>
            </div>
            <div className="field-body">
                <div className="control">
                    <input type="text" className="input" placeholder="Origin of the song" value={origin} onChange={e => setOrigin(e.target.value)} disabled={isSubmitting} />
                </div>
            </div>
        </div>
        <div className="field is-horizontal">
            <div className="field-label is-normal">
                <label className="label">Title</label>
            </div>
            <div className="field-body">
                <div className="control">
                    <input type="text" className="input" placeholder="Title of the song" value={title} onChange={e => setTitle(e.target.value)} disabled={isSubmitting} />
                </div>
            </div>
        </div>
        <div className="field is-horizontal is-grouped">
            <div className="control">
                <button className="button is-primary" onClick={submit} disabled={isSubmitting}>
                    Submit{isSubmitting ? <FontAwesomeIcon className="ml-3" icon="spinner" pulse={true} /> : ''}
                </button>
            </div>
            <div className="control">
                <button className="button is-link" onClick={props.close}>Cancel</button>
            </div>
        </div>
    </form>;
};

export default AddSongForm;
