import { rtk } from "./api";
import auth from "./auth";
import connections from "./connections";
import modules from "./modules";
import notifications from "./notifications"
import songlist from "./songlist";
import userSettings from "./user/settings"
import video from "./video";
import webhooks from "./webhooks";

const store = {
  webhooks: webhooks,
  auth: auth,
  connections: connections,
  songlist: songlist,
  settings: userSettings,
  notifications: notifications,
  modules: modules,
  video: video,
  [rtk.reducerPath]: rtk.reducer
}

export default store;
