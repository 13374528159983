import {useState} from 'react';
import {SongsTable} from "./Songlist/SongsTable";
import AddSongForm from './Songlist/AddSongForm';
import Modal from './Modal';
import Loading from '../Loading';
import {Notification} from "./Notification";
import { NotificationType } from '../redux/reducers/notifications';
import { useGetSongsQuery } from '../redux/reducers/api';

const Songlist = () => {
  const [showAddSong, setShowAddSong] = useState(false);

  const { data: songs, isLoading, isError } = useGetSongsQuery()

  const close = e => {
      if (e) {
          e.preventDefault();
      }
      setShowAddSong(false);
  };

  return <div>
    <h1 className={"is-size-1"}>Songlist</h1>
    {isError ? <Notification type={NotificationType.WARNING} identifier={Symbol('undefined songlist')}><p>No default songlist found</p></Notification> : <></>}
    <nav className="navbar is-dark">
        <div className="navbar-menu">
            <div className="navbar-item">
                <a onClick={e => setShowAddSong(true)} className="button is-primary">Add</a>
            </div>
        </div>
    </nav>
    {isLoading ? <Loading /> : <SongsTable songs={songs || []} />}
    <Modal open={showAddSong} close={close}><AddSongForm close={close} /></Modal>
  </div>
}

export default Songlist;

/*
class Songlist extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            showAddSongForm: false,
        };

        this.addSong = this.addSong.bind(this);
        this.props.auth0.getAccessTokenSilently().then(t => this.onAuthenticated(t));
    }

    onAuthenticated(token) {
        this.props.dispatch(fetchSongs(token));
    }

    renderLoading() {
        return <div>
            <h1>Songlist</h1><p>Loading...</p>
        </div>;
    }

    addSong() {
        this.setState({showAddSongForm: true})
    }

    render() {
        if (this.props.songlist.status === 'loading') {
            return this.renderLoading();
        }
        let songs = [];
        if (this.props.songlist.songs != null) {
            songs = this.props.songlist.songs;
        }
        return <div>
            <h1 className={"is-size-1"}>Songlist</h1>
            <nav className="navbar is-dark">
                <div className="navbar-menu">
                    <div className="navbar-item">
                        <a onClick={this.addSong} className="button is-primary">Add</a>
                    </div>
                </div>
            </nav>
            <SongsTable songs={songs} />
            { this.state.showAddSongForm ? <Modal open={this.state.showAddSongForm}><AddSongForm close={e => this.setState({showAddSongForm: false})} /></Modal> : '' }
        </div>;
    }
}

const mapStateToProps = state => {
    const { songlist } = state;
    return { songlist };
}

export default withAuth0(connect(mapStateToProps)(Songlist));
*/
