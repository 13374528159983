import React from 'react';
import { createRoot } from 'react-dom/client';
import './overrides.scss';
import { DashboardLayout } from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router';
import Vods from './modules/Vods';
import { Dashboard } from './modules/Dashboard';
import Songlist from './modules/Songlist';
import { Connections } from './modules/Connections';
import Webhooks from './modules/Webhooks';
import Usernames from './modules/admin/Usernames';
import OauthRedirect from './modules/Connections/OauthRedirect';

let isEnvValid = (): boolean => {
  if (
    process.env.REACT_APP_API_ENDPOINT === '' ||
    process.env.REACT_APP_AUTHZERO_AUDIENCE === '' ||
    process.env.REACT_APP_AUTHZERO_CLIENT_ID === '' ||
    process.env.REACT_APP_AUTHZERO_DOMAIN === ''
  ) {
    return false;
  }

  return true;
};

if (!isEnvValid()) {
  var h1 = document.createElement('h1');
  h1.style.textAlign = 'center';
  h1.style.fontSize = '3em';
  h1.style.color = 'white';
  h1.innerText = 'Everything is broken 😱';
  document.getElementById('root')?.append(h1);
} else {

  const homeLoader = () => {
    const onVods = window.location.hostname.indexOf('vods.veld.live') >= 0;
    if (!onVods) {
      return redirect('/dashboard')
    }
  }

  const router = createBrowserRouter([
    { path: '/', element: <h1 className="is-size-3">Why are you here</h1>, loader: homeLoader, errorElement: <p>There is no spoon</p> },
    { path: '/vods/:id', Component: Vods }, // public
    { path: '/dashboard', Component: DashboardLayout, children: [
      { index: true, Component: Dashboard },
      { path: 'songlist', Component: Songlist },
      { path: 'connections', Component: Connections, children: [
        { path: 'redirect/:service', Component: OauthRedirect },
      ]},
      { path: 'admin', children: [
        { path: 'webhooks', Component: Webhooks },
        { path: 'usernames', Component: Usernames },
      ]},
    ]},
  ])

  createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>,
    
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
