import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useState} from "react";
import {useDispatch} from "react-redux";
import {authorizeConnection} from "../../redux/reducers/connections";
import {useAuth0} from "@auth0/auth0-react";
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export function Connection(props) {
    const dispatch = useDispatch();
    const [authToken, setAuthToken] = useState('');
    const [seModalActive, setSeModalActive] = useState(false);
    const [seCode, setCode] = useState('');
    const {getAccessTokenSilently} = useAuth0();

    getAccessTokenSilently().then(t => setAuthToken(t));

    const serviceLogo = service => {
        let img = '';
        switch (service) {
            case 'twitch':
            case 'discord':
                img = <img src={"/img/"+service+"-128.png"} alt={service} />;
                break;
            case 'streamelements':
                img = <img src={"/img/" + service + "-128.jpg"} alt={service} />;
                break;
            default:
              img = ''
        }

        return <p className="image is-64x64">
            {img}
        </p>;
    };

    const serviceDescription = service => {
        switch (service) {
            case 'twitch':
                return <p>
                    <strong>Twitch</strong> Connect your Twitch account to enable... things
                </p>;
            case 'discord':
                return <p>
                    <strong>Discord</strong> Connect your Discord account to add VeldBot to your server which can help with things like: going live notifications, locking shoutout channels based on certain rules and other stuff...
                </p>;
            case 'streamelements':
                return <p>
                    <strong>StreamElements</strong> Connect with StreamElements to allow Veld Services to read incoming alerts. This is required if you want to automatically assign priority to song requests for example.
                </p>;
            default:
                return <p>😂 Veld added a connection without specifying a description, silly goose</p>
        }
    };

    const authorizeStreamElements = (_, e) => {
        if (e) { e.preventDefault(); }

        setSeModalActive(true);
    };

    const serviceStatus = connection => {
        if (connection.hasValidToken) {
            return <FontAwesomeIcon icon={faCircleCheck} className="fa-4x has-text-success" />;
        }

        if (connection.service === 'streamelements') {
            return <FontAwesomeIcon icon={faCircleXmark} className="fa-4x has-text-danger" onClick={e => authorizeStreamElements(connection, e)} />;
        }

        return <a href={connection.authorizeUrl}>
            <FontAwesomeIcon icon={faCircleXmark} className="fa-4x has-text-danger" />
        </a>;
    };

    const streamElementsModal = (con) => {
        const params = new URLSearchParams();
        params.set('code', seCode);
        return authToken && (<div className={"modal" + (seModalActive ? " is-active" : "")}>
            <div className="modal-background" />
            <div className="modal-content">
                <div className="box">
                    <p>To authorize StreamElements, click the "Start authorization" button, this will open a new window. When you follow the steps there, you'll end up on harmonize.dev with the authorization code. Copy that code and paste it in the field shown below.</p>
                    <div className="control">
                        <button className="button is-info" onClick={_ => window.open(con.authorizeUrl)}>Start authorization</button>
                    </div>
                    <div className="field has-addons">
                        <input className="input" placeholder="Paste authorization code here" value={seCode} onChange={e => setCode(e.target.value)} />
                        <div className="control">
                            <button className="button is-success" onClick={_ => dispatch(authorizeConnection(authToken, con.service, params)).then(_ => setSeModalActive(false))}>Submit code</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-close is-large" aria-label="close" onClick={_ => setSeModalActive(false)} />
        </div>)
    };

    const con = props.connection;
    return <div className="box">
        <div className="columns">
            <div className="column">
                <article className="media">
                    <figure className="media-left">
                        {serviceLogo(con.service)}
                    </figure>
                    <div className="media-content">
                        {serviceDescription(con.service)}
                    </div>
                </article>
            </div>
            <div className="column is-one-fifth">
                {serviceStatus(con)}
            </div>
        </div>
        {streamElementsModal(con)}
    </div>;
}
