import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Module, UserModule } from "./modules"
import { Song } from "./songlist";
import { mapBadgesById, Video } from "./video";
import { JsonUsernameChanges } from "./username-changes";

// Redux RTK 
export const rtk = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT + '/api',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('auth_token')
      if (token) {
        headers.append('Authorization', 'Bearer ' + token)
      }

      return headers
    },
  }),
  tagTypes: ['Modules', 'UserModules'],
  endpoints: build => ({
    // Modules
    getModules: build.query<Module[], void>({
      query: () => '/modules',
      providesTags: (result) => result ? result.map(({ id }) => ({ type: 'Modules', id })) : [],
    }),
    getUserModules: build.query<UserModule[], void>({
      query: () => '/user/modules',
      providesTags: (result) => result ? result.map(({ ModuleId: id }) => ({ type: 'UserModules', id })) : [],
    }),
    enableModule: build.mutation({
      query: (moduleId) => ({
        url: "/user/modules/" + moduleId,
        method: "PUT",
        body: JSON.stringify({ ModuleId: moduleId, Enabled: true })
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        dispatch(
          rtk.util.updateQueryData('getUserModules', undefined, (userModules) => {
            userModules.map(m => {
              if (m.ModuleId === data.ModuleId) {
                m.Enabled = data.Enabled
              }
              return m
            })
          }),
        )
      },
    }),
    disableModule: build.mutation({
      query: (moduleId) => ({
        url: "/user/modules/" + moduleId,
        method: "DELETE",
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        dispatch(
          rtk.util.updateQueryData('getUserModules', undefined, (userModules) => {
            userModules.map(m => {
              if (m.ModuleId === data.ModuleId) {
                m.Enabled = data.Enabled
              }
              return m
            })
          }),
        )
      },
    }),

    // Song 
    getSongs: build.query<Song[], void>({
      query: () => '/songlist/default/songs',
    }),

    // VOD
    getVideo: build.query<Video, string>({
      query: (videoId: string) => '/vod/' + videoId,
      transformResponse: (response: Video, meta, arg) => {
        const video = response
        if (video.channelBadges !== null) {
          video.badgeSets = mapBadgesById(video.channelBadges)
        }
        return video
      },
    }),
    getChat: build.query<any, string>({
      query: (videoId: string) => '/vod/' + videoId + '/chat.json',
    }),

    // User
    patchUser: build.mutation({
      query: (user) => ({
        url: '/user',
        method: 'PATCH',
        body: JSON.stringify(user),
      }),
    }),

    // Admin stuff
    // Usernames
    getUsernameChanges: build.query<JsonUsernameChanges, void>({
      query: () => '/admin/twitch/usernames',
    })
  })
});

export const {
  usePatchUserMutation,
  useGetModulesQuery,
  useGetUserModulesQuery,
  useEnableModuleMutation,
  useDisableModuleMutation,
  useGetSongsQuery,
  useGetVideoQuery,
  useGetChatQuery,
  useGetUsernameChangesQuery,
} = rtk
