import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  state: string
  user?: {
    auth0: { sub: string } 
    default_songlist: string,
    email: string,
    id: number,
    name: string,
    roles: string[]
  }
}

const initialState = {state: 'idle', user: undefined } satisfies AuthState as AuthState

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    userSynced(state, action) {
      state.state = 'idle'
      state.user = action.payload
    },
    syncing(state) {
      state.state = 'syncing'
    },
    failedToSyncUser(state) {
      state.state = 'failed'
      state.user = undefined
    }
  }
})

export const { userSynced, syncing, failedToSyncUser } = authSlice.actions;

// export const syncUser = (user, token) => async dispatch => {
//   window.setTimeout(async () => {
//     const res = await syncUserWithBackend(user, token);
//     dispatch(userSynced(res));
//   }, 500); // delay to avoid token timing issues
// }

export default authSlice.reducer
