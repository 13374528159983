import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export type Song = {
  title: string,
  origin: string
}

type SonglistSlice = {
  songs: Song[]
  status: string
  error?: string
}

const initialState: SonglistSlice = {
  songs: [],
  status: 'idle',
  error: undefined
}

const songlistSlice = createSlice({
  name: 'songs',
  initialState: initialState,
  reducers: {
    songsLoading(state) {
      state.status = 'loading';
    },
    songsLoaded(state, action) {
      state.songs = action.payload;
      state.status = 'idle';
    },
    songsError(state, error: PayloadAction<AxiosError>) {
      if (error.payload.response?.status === axios.HttpStatusCode.PreconditionFailed) {
        state.status = 'undefined'
        return
      }
      state.status = 'errored';
      state.error = error.payload.message;
    }
  }
});

export const { songsLoading, songsLoaded, songsError } = songlistSlice.actions;

//export const addSong = (songlist, song) => dispatch => {
//};

export default songlistSlice.reducer;
