import {useDispatch, useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {Connection} from "./Connections/Connection";
import {useEffect} from "react";
import {fetchConnections} from "../redux/reducers/connections";
import { Outlet } from "react-router";

export function Connections() {
    const dispatch = useDispatch();
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    const slice = useSelector((state) => state.connections);

    useEffect(() => {
        getAccessTokenSilently().then((token) => dispatch(fetchConnections(token)));
     }, [dispatch, getAccessTokenSilently]);

    return isAuthenticated && (<>
        <h1 className={"is-size-1"}>Connections</h1>
        <Outlet />
        {slice.connections.map(c => {
            return <Connection key={"conn-" + c.service} connection={c} />;
        })}
    </>)
}
