import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export enum NotificationType {
  ERROR='error',
  WARNING='warning',
  SUCCESS='success',
}

export interface AppNotification {
  title: string,
  type: NotificationType
  description: string,
}

interface AppNotifications {
  notifications: AppNotification[]
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: { notifications: [] },
  reducers: {
    AddError: (state: AppNotifications, action: PayloadAction<AppNotification>) => {
      state.notifications.push(action.payload)
    },
    RemoveNotification: (state: AppNotifications, action: PayloadAction<number>) => {
      delete state.notifications[action.payload]
    }
  },
  // extraReducers: builder => {
  //   builder.addCase(createAction<ApiErrorPayload>(API_ERROR), (state: AppNotifications, action: PayloadAction<ApiErrorPayload>) => {
  //     let description = action.payload.errorResponse.message
  //     const response = (action.payload.errorResponse.response?.data as ErrorResponse)||undefined
  //     if (response !== undefined) {
  //       description = description + ": " + response?.error
  //     }
  //     state.notifications.push({
  //       title: "Oops, something didn't go as planned",
  //       description: description,
  //       type: NotificationType.ERROR,
  //     })
  //   })
  // },
})

export const { AddError, RemoveNotification } = notificationsSlice.actions

export const getNotifications = (state: RootState) => state.notifications.notifications

export default notificationsSlice.reducer
